const mail = {
    state: {
        // 未读邮箱数量
        unreadCount: 0,
    },
    getters: {
        // 获取邮箱未读数量
        getMailUnreadCount(state) {
            return state.unreadCount;
        }
    },
    mutations: {
        ADDCOUNT(state) {
            state.unreadCount++;
        },
        SETCOUNT(state, number) {
            state.unreadCount = number;
        },
        REDUCECOUNT(state) {
            state.unreadCount--;
        }
    },
    actions: {
        // 增加邮箱未读数量
        addMailUnreadCount({ commit }) {
            commit('ADDCOUNT');
        },
        // 修改邮箱未读数量
        modifyMailUnreadCount({ commit }, number) {
            commit('SETCOUNT', number);
        },
        // 减少邮箱未读数量
        reduceMailUnreadCount({ commit }) {
            commit('REDUCECOUNT');
        }
    },
};

export default mail;
