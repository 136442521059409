const permi = {
    state: {
        dynamicRoutes: []
    },
    getters: {
        dynamicRoutes: state => state.dynamicRoutes
    },
    mutations: {
        DYNAMIC_ROUTES(state, routes) {
            state.dynamicRoutes = routes
        }
    },
    actions: {
        dynamicRoutes({ commit }, routes) {
            commit('DYNAMIC_ROUTES', routes)
        }
    }
}



export default permi