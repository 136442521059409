import router from "@/router/index";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { Message } from "element-ui";
import { getRouters } from "@/api/login";
import { fnAddDynamicMenuRoutes } from "@/router/getAsyncRouter";
import { getToken } from "@/utils/auth";

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});
// 白名单
const whiteList = ["/login"];

// 导航守卫
router.beforeEach((to, from, next) => {
  // 打开进度条
  NProgress.start();
  try {
    // 判断是否已经获取过动态菜单，未获取，则需要获取一次
    if (store.getters.dynamicRoutes.length === 0) {
      if (whiteList.indexOf(to.path) !== -1) {
        // 白名单直接放行
        next();
      } else {
        getRouters().then((res) => {
          if (res.code === 200 && res.data.length > 0) {
            let menuRouter = fnAddDynamicMenuRoutes(res.data);
            store.dispatch("dynamicRoutes", menuRouter).then(() => {
              store.getters.dynamicRoutes.forEach((res) => {
                router.addRoute(res);
              });
              // 跳转
              next({ ...to, replace: true });
            });
          } else {
            Message.error("获取路由失败");
            next({ path: "/login" });
          }
        });
      }
    } else {
      // 路由已存在或已缓存路由
      if (to.meta.requireAuth) {
        if (getToken("token") !== null) {
          // 判断本地是否存在token
          next();
        } else {
          // 未登录,跳转到登陆页面
          next({ path: "/login" });
        }
      } else {
        if (whiteList.indexOf(to.path) !== -1) {
          next();
        } else {
          if (getToken("token") !== null) {
            // 判断本地是否存在token
            localStorage.setItem("activeIndex", "index");
            next(`/`);
          } else {
            next(`/login`);
          }
        }
      }
    }
  } catch (error) {
    Message.error("获取路由出错");
    next(`/login`);
  }
});

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});
