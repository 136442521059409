// 用于处理动态菜单数据，将其转为 route 形式
export function fnAddDynamicMenuRoutes(menuList = []) {
  // 用于保存路由数据
  let route = [];
  // 遍历数据
  for (let i = 0; i < menuList.length; i++) {
    route.push(getRoute(menuList[i]));
  }
  // 返回路由结果
  return route;
}

// 返回路由的基本格式
function getRoute(item) {
  // 路由基本格式
  let route = {
    // 路由的路径
    path: "/" + item.path,
    // 路由所在组件
    component: () => import(`@/layout/index.vue`),
    children: item.path,
    // 路由的子路由
    children: [
      {
        path: "/" + item.path,
        component: () => import(`@/views/${item.component}`),
        name: item.routeName,
        meta: { requireAuth: true },
      },
    ],
  };
  // 返回 route
  return route;
}
