<template>
  <div>
    <el-card shadow="always" :body-style="{ padding: '0px', height: '50px' }">
      <button class="button" @click="conllapse">
        <i :class="iconfont" class="button-i"></i>
      </button>
      <el-badge :value="unreadNumber" class="avatar" :max="99" :hidden="!unreadNumber > 0">
        <el-popover placement="bottom" trigger="hover" popper-class="customPopper">
          <div class="avatar-btn">
            <el-badge :value="unreadNumber" :max="99" :hidden="!unreadNumber > 0">
              <el-button type="text" @click="toEmaile()">收件箱</el-button>
            </el-badge>
          </div>
          <el-divider></el-divider>
          <el-button type="text" style="display: block;" @click="updatePassword">修改密码</el-button>
          <el-divider></el-divider>
          <el-button type="text" style="display: block;margin: 0 0;" @click="logout">退出登录</el-button>
          <el-avatar
            slot="reference"
            :size="35"
            src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          ></el-avatar>
        </el-popover>
      </el-badge>
    </el-card>
    <!-- 修改密码弹窗 -->
    <PassWord :drawer.sync="draper"></PassWord>
    <!-- WebSocket -->
    <WebSocket></WebSocket>
  </div>
</template>

<script>
import { logout } from "@/api/login";
import { removeToken } from "@/utils/auth";
import { getUnreadEmailCount } from "@/api/email/inbox";
export default {
  data() {
    return {
      iconfont: "iconfont icon-shouqicaidan",
      draper: false,
    };
  },
  methods: {
    conllapse() {
      this.$store.state.menu.isCollapse = !this.$store.state.menu.isCollapse;
      this.iconfont = this.$store.state.menu.isCollapse
        ? "iconfont icon-zhankaicaidan"
        : "iconfont icon-shouqicaidan";
    },
    // 退出登录
    logout() {
      this.$confirm("确定要退出登录吗？", "提示", { type: "warning" }).then(
        () => {
          logout().then(() => {
            removeToken();
            location.href = "/login";
          });
        }
      );
    },
    // 修改密码
    updatePassword() {
      this.draper = true;
    },
    // 跳转收件箱
    toEmaile() {
      this.$router.push("/inbox");
      this.$store.commit("setActiveIndex", "inbox");
    },
    // 获取未读邮件数量
    getMailUnreadCount() {
      getUnreadEmailCount().then((res) => {
        this.$store.dispatch("modifyMailUnreadCount", res.data);
      });
    },
  },
  components: {
    PassWord: () => import("@/layout/components/password"),
    WebSocket: () => import("@/components/socket"),
  },
  computed: {
    unreadNumber() {
      return this.$store.getters.getMailUnreadCount;
    },
  },
  created() {
    // 获取未读邮件数量
    this.getMailUnreadCount();
  },
};
</script>

<style scoped>
.avatar {
  position: absolute;
  right: 30px;
  top: 11px;
  cursor: pointer;
}

.button {
  height: 50px;
  width: 50px;
  padding: 0px;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
}

.button-i {
  font-size: 25px;
}

.button:hover {
  background-color: #f5f5f5;
}
.avatar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.customPopper {
  min-width: 50px !important;
}
.el-divider--horizontal {
  margin: 0 0 !important;
}
</style>