import request from '@/utils/request'


export function getEmailList(params) {
    return request({
        url: '/email/inbox/list',
        method: 'get',
        params
    })
}

export function deleteEmail(ids) {
    return request({
        url: '/email/inbox/deleteEmails',
        method: 'delete',
        data: ids
    })
}

export function markEmail() {
    return request({
        url: '/email/inbox/markAllAsRead',
        method: 'put',
    })
}

// 获取未读邮件数量
export function getUnreadEmailCount() {
    return request({
        url: '/email/inbox/getUnreadEmailCount',
        method: 'get',
    })
}