import axios from "axios";
import errorCode from "./errorCode";
import { Notification, MessageBox, Message } from "element-ui";
import { getToken } from "./auth";

// 设置发送json格式参数
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 请求队列
const requestList = [];
let cancelToken = axios.CancelToken;

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "/api",
  // 超时
  timeout: 10000,
});

// 请求拦截器
service.interceptors.request.use((config) => {
  // 防止重复请求
  config.cancelToken = new cancelToken(cancel => {
    let requestFlag =
      JSON.stringify(config.url) +
      JSON.stringify(config.data) +
      "&" +
      config.method;
    if (config.method === "post" || config.method === "put") {
      if (requestList.includes(requestFlag)) {
        //请求标记已经存在，则取消本次请求，否则在请求列表中加入请求标记
        cancel("请勿重复提交。");
      } else {
        requestList.push(requestFlag);
      }
    }
  });
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false;
  if (getToken() && !isToken) {
    // 让每个请求携带自定义token
    config.headers["token"] = "Bearer " + getToken();
  }
  return config;
});

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 请求标记从请求队列中移除
    let requestFlag =
      JSON.stringify(res.config.url) +
      JSON.stringify(res.config.data) +
      "&" +
      res.config.method;
    requestList.splice(
      requestList.findIndex(item => item === requestFlag),
      1
    );
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg || errorCode[code] || errorCode["default"];
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code === 401) {
      if (getToken()) {
        MessageBox.confirm("登录状态已过期，请重新登录", "系统提示", {
          type: "warning",
          showCancelButton: false,
          showClose: false
        }).finally(() => {
          location.href = "/login";
        });
      } else {
        location.href = "/login";
      }
      return Promise.reject("error");
    } else if (code === 403) {
      Message({ message: msg, type: "error" });
      return Promise.reject(new Error(msg));
    } else if (code === 500) {
      Message({ message: msg, type: "error" });
      return Promise.reject(new Error(msg));
    } else if (code === 601) {
      Message({ message: msg, type: "warning" });
      return Promise.reject("error");
    } else if (code !== 200) {
      Notification.error({ title: msg });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      let code = message.substr(message.length - 3);
      if (code === "502") {
        message = "系统正在维护，请稍后再试";
      } else {
        message = "系统接口" + code + "异常";
      }
    }
    Message({ message: message, type: "error", duration: 5 * 1000 });
    return Promise.reject(error);
  }
);

export default service;
