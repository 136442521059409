import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu'
import permi from './modules/permission'
import mail from './modules/mail'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    menu,
    permi,
    mail
  }
})
