<template>
  <el-container>
    <el-aside :width="$store.state.menu.isCollapse ? '50px' : '200px'">
      <Aside></Aside>
    </el-aside>
    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-main>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { Aside, Header } from "./components";
export default {
  components: {
    Aside,
    Header,
  },
  data() {
    return {
      fade: "transitionLeft",
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.el-container {
  height: 100%;
}

.el-header {
  padding-left: 0;
  padding-right: 0;
}
.el-aside {
  transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
</style>
